import { useEffect } from "react"

const useBodyClass = (classNames = []) => {
  useEffect(() => {
    document.body.classList.add(...classNames)

    return () => document.body.classList.remove(...classNames)
  })
}

export default useBodyClass
