import React, { useState } from "react"
import { Link } from "gatsby"

import Logo from "./Logo"
import Menu from "./Menu"
import NoScrollBody from "./NoScrollBody"

const MENU_OPEN = true
const MENU_CLOSE = false

const Header = () => {
  const [isMenuVisible, setMenu] = useState(MENU_CLOSE)

  return (
    <>
      {isMenuVisible && <NoScrollBody />}
      <header>
        <button
          className="btn-menu"
          aria-label="Nav panel"
          onClick={() => setMenu(!isMenuVisible)}
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"></path>
          </svg>
        </button>
        <div className="logo">
          <Link to="/">
            <Logo />
          </Link>
        </div>
        <Menu className="menu-desktop" />
        <aside
          className={`menu-mobile ${
            isMenuVisible ? "menu-mobile__opened" : ""
          }`}
        >
          <Menu
            onClick={e =>
              void (e.target === e.currentTarget && setMenu(MENU_CLOSE))
            }
          />
        </aside>
      </header>
    </>
  )
}

export default Header
