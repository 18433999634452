import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Logo, { LOGO_TYPES } from "./Logo"

const Footer = () => {
  const { site } = useStaticQuery(graphql`
    query FooterQuery {
      site {
        siteMetadata {
          contactEmail
        }
      }
    }
  `)

  const contactEmail = site.siteMetadata?.contactEmail

  return (
    <footer>
      <div className="inner">
        <div className="row">
          <div className="col">
            <div className="logo">
              <Logo type={LOGO_TYPES.monochrome} />
            </div>
          </div>
          <div className="col">
            <h2 className="text__m">Disclaimer</h2>
            <p>This site is not affiliated with YouTube in any way.</p>
          </div>
          <div className="col">
            <h2 className="text__m">Contact</h2>
            <p>
              <a href={`mailto:${contactEmail}`}>{contactEmail}</a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
