import { useBodyClass } from "../hooks"

const NO_SCROLL_CLASSNAME = "no-scroll"

const NoScrollBody = () => {
  useBodyClass([NO_SCROLL_CLASSNAME])

  return null
}

export default NoScrollBody
