import React from "react"

import LogoLight from "../images/logo.svg"
import LogoMonochrome from "../images/logo-monochrome.svg"

export const LOGO_TYPES = {
  light: Symbol("logo-light"),
  monochrome: Symbol("logo-monochrome"),
}

const Logo = ({ type = LOGO_TYPES.light }) => {
  let logo

  switch (type) {
    case LOGO_TYPES.light:
      logo = LogoLight
      break

    case LOGO_TYPES.monochrome:
      logo = LogoMonochrome
      break
  }

  return <img src={logo} alt="YouTube Video Finder" />
}

export default Logo
