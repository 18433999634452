import React from "react"
import { Link } from "gatsby"

const Menu = props => (
  <nav {...props}>
    <ul>
      <li>
        <Link to="/" activeClassName="active">
          Home
        </Link>
      </li>
      <li>
        <Link to="/backup-videos/" activeClassName="active">
          Backup Videos
        </Link>
      </li>
      <li>
        <Link to="/recover-videos/" activeClassName="active">
          Recover Videos
        </Link>
      </li>
      <li>
        <Link to="/feedback/" activeClassName="active">
          Feedback
        </Link>
      </li>
      <li>
        <Link to="/about/" activeClassName="active">
          About
        </Link>
      </li>
    </ul>
  </nav>
)

export default Menu
